<template></template>
<!-- Login template page -->
<script lang="ts" setup>
definePageMeta({
  layout: "landing-page",
  layoutProps: { elevatedNavbar: false, navbarVariant: "transparent" },
  metaData: "meta.homepage.auth.login",
  middleware: ["not-authenticated-only"],
  scrollToTop: false,
})

const login = useLogin()

onBeforeMount(() => login.open())
</script>
